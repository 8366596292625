.dashboardButton {
  border: 2px solid transparent;
  color: #1e559a;
  border-radius: 4px;
  padding: 1rem;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  transition: all 0.2s ease;
  //   grid-row-gap: 1rem;
  align-content: start;
  justify-items: center;
  // margin-bottom: 1rem;
  text-align: center;
  height: 100%;
  text-decoration: none;
  //   color: white;
  font-size: 1.25rem;
  svg {
    font-size: 2rem;
  }
  font-weight: 500;
  transform: scale(0.85);

  &:hover {
    transform: scale(0.95);
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);
  }
}

.noDecoration {
  text-decoration: none !important;
}

.badgeRegistration {
  background-color: #003c7d !important;
  position: absolute;
  margin-right: 15px;
  // left: 0;
  right: 0;
  bottom: 32px;
  width: 90%;
  padding: 2px;
  color: white;
  font-family: inherit;
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.badgeRegistration2 {
  // background-color: #003c7d !important;
  background-color: #28a745;
  position: absolute;
  margin-right: 15px;
  // left: 0;
  right: 0;
  bottom: 32px;
  width: 90%;
  padding: 2px;
  color: white;
  font-family: inherit;
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
}

.badge {
  background-color: #f1f1f1 !important;
  padding: 15px;
  border-radius: 12px;
  margin: none;
}

.badge2 {
  background-color: #ffffff !important;
  padding: 15px 30px 15px 30px;
  border-radius: 12px;
  margin: none;
  // margin-left: -px;
}

.badge3 {
  background-color: #2d7dd2 !important;
  position: absolute;
  right: 4px;
  bottom: 28px;
  padding: 8px;
  color: white;
  font-family: inherit;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 1px;
}



.smallFontSize {
  @media (max-width: 576px) {
    margin-top: 50px !important;
  }
}

.badge4 {
  background-color: #ffffff !important;
  position: absolute;
  right: 4px;
  bottom: 28px;
  padding: 8px;
  color: white;
  font-family: inherit;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 1px;
}
