.footerGrid {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 1rem;
  grid-template-areas: 'logo company link1 link2 . ' 'logo text text text text ';
  font-size: 1.2rem;

  .company {
    grid-area: company;
  }
  .link1 {
    grid-area: link1;
  }
  .link2 {
    grid-area: link2;
  }
  .text {
    grid-area: text;
    border-top: 1px solid #1e559a;
    font-size: 0.75rem;
    padding-top: 0.23rem;
  }

  .logo {
    width: 120px;
    grid-area: logo;
    align-self: center;
    img {
      width: 100%;
    }
  }
}

.footer {
  padding: 0.5rem 0rem;
  margin-top: 4rem;
  // background-color: #f5f5f5;
  // background-color: #eaebed;
  border-top: 2px solid #f7fafd;
}
